@charset "UTF-8";
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

* {
  box-sizing: border-box;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

canvas {
  vertical-align: bottom;
}

/**
*
*	common properties
*
**/
html {
  -webkit-text-size-adjust: none;
  font-size: 62.5%;
}

body {
  color: #000;
  margin: 0;
  padding: 0;
  font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.local-en {
  font-family: "Roboto", sans-serif;
}

img, a {
  border: none;
  outline: none;
}

label {
  cursor: pointer;
}

p {
  line-height: 1.6em;
}

main {
  display: block;
}

.cols:after,
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

ol, ul {
  list-style: none;
}

a:link,
a:visited {
  color: #333;
  text-decoration: none;
  -webkit-transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
a:link.has-arrow,
a:visited.has-arrow {
  color: #00c6bf;
}
a:link.has-arrow::after,
a:visited.has-arrow::after {
  display: inline-block;
  margin-left: 5px;
  width: 6px;
  height: 10px;
  content: "";
}
a:link.em,
a:visited.em {
  color: #00c6bf;
}

a:hover,
a:active {
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

img, svg {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mt80 {
  margin-top: 80px;
}

.mt90 {
  margin-top: 90px;
}

.mt100 {
  margin-top: 10px;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
}
ins {
  display: inline-block;
  vertical-align: bottom;
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

.no-scroll {
  overflow-y: hidden;
}

.modal-hidden-contents {
  display: none;
}

#modal-base-layer {
  background: #000;
  color: #000;
  display: none;
  position: fixed;
  _position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100px;
  z-index: 9900;
  text-align: center;
}

#modal-contents-layer {
  display: none;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 9901;
}

#modal-contents-layer #modal-iscroll-container-inner {
  overflow: hidden;
}

input[type=text],
input[type=number] {
  box-sizing: border-box;
  padding: 8px 16px;
  width: 8em;
  height: 36px;
  font-size: 1.8rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
input[type=text]:focus,
input[type=number]:focus {
  outline: none;
}

textarea {
  box-sizing: border-box;
  padding: 8px 16px;
  width: 100%;
  max-width: 400px;
  height: 8em;
  font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
  font-size: 1.8rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
textarea:focus {
  outline: none;
}

.button {
  box-sizing: border-box;
  display: inline-block;
  padding: 2px 24px 3px 24px;
  color: #fff;
  letter-spacing: 0.05em;
  line-height: 36px;
  border-radius: 40px;
  background-color: #186fc8;
  box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.4);
}
.button.conv {
  padding: 16px 40px 20px 40px;
  min-width: 200px;
  font-size: 1.8rem;
  background-color: #00c6bf;
}
.button.conv:hover, .button.conv:active {
  padding: 16px 40px 20px 40px;
  background-color: #00aea8;
}
.button:link, .button:visited {
  color: #fff;
}
.button:hover, .button:active {
  color: #fff;
  text-decoration: none;
  background-color: #1460ad;
  box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.2);
}
.button .fas {
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: 8px;
  font-size: 0.8rem;
}
.button .fa-circle {
  color: #ff0000;
}

.container {
  padding: 0 24px;
}

#header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  color: #fff;
  background-color: #00c6bf;
}
@media print {
  #header {
    display: none;
  }
}
#header .container {
  position: relative;
  padding-top: 7px;
  height: 64px;
}
#header .site-name {
  display: inline-block;
  font-family: "Baloo Bhai", cursive;
  font-size: 3.2rem;
  font-weight: 400;
  letter-spacing: 0.05em;
}
#header .slogan {
  vertical-align: middle;
  position: relative;
  margin-left: 16px;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05em;
}
#header a:link, #header a:visited {
  color: #fff;
}
#header a:hover, #header a:active {
  opacity: 0.7;
  text-decoration: none;
}
#header .lang {
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
#header .lang li {
  display: inline-block;
  margin-left: 8px;
}
#header .lang a {
  display: inline-block;
  padding: 4px 10px 8px 10px;
  color: #00c6bf;
  border-radius: 40px;
  background-color: #fff;
}
#header .lang a:hover, #header .lang a:active {
  text-decoration: none;
}

.local-ja #header .slogan {
  font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
}

#trunk {
  min-height: calc(100vh - 64px);
  padding: 64px 0 0 0;
  page-break-inside: avoid;
  background-color: #f8f6f1;
}
@media print {
  #trunk {
    padding-top: 0;
  }
}

#footer {
  text-align: center;
}
@media print {
  #footer {
    display: none;
  }
}
#footer .container {
  padding-top: 24px;
  padding-bottom: 24px;
}
#footer small {
  font-size: 1.2rem;
}

.page-title {
  margin-bottom: 40px;
  font-size: 2.4rem;
  font-weight: 500;
}

.worksheets-page #trunk {
  background-color: transparent;
}

.home-page .page-title {
  font-size: 1.8rem;
  font-weight: 400;
}
.home-page .page-contents .container {
  padding-top: 40px;
  padding-bottom: 40px;
}
.home-page .page-contents dl {
  letter-spacing: 0.05em;
}
.home-page .page-contents dl dt {
  margin-bottom: 8px;
  color: #00c6bf;
  font-size: 2rem;
  line-height: 1.8em;
}
.home-page .page-contents dl dd {
  font-size: 1.6rem;
}
.home-page .page-contents dl dd li {
  line-height: 1.6em;
  letter-spacing: 0.05em;
}
.home-page .page-contents dl dd li::before {
  vertical-align: top;
  margin-right: 8px;
  color: #00c6bf;
  font-family: "Font Awesome 5 Pro";
  font-size: 0.8rem;
  font-weight: 900;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  content: "";
}

.basic-addition-worksheets-page .page {
  max-width: 1000px;
}
.basic-addition-worksheets-page .page .container {
  padding-bottom: 40px;
}
@media print {
  .basic-addition-worksheets-page .page .container {
    padding-left: 0;
    padding-right: 0;
  }
}
.basic-addition-worksheets-page .upload {
  margin: 24px 0 0 0;
}
.basic-addition-worksheets-page .library {
  min-height: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #ecf2f9;
  padding: 20px 20px;
  font-size: 0;
}
.basic-addition-worksheets-page .library.visible {
  display: block;
}
.basic-addition-worksheets-page .library .image {
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  position: relative;
  width: 80px;
  padding-top: 50px;
  cursor: -webkit-grab;
  cursor: grab;
}
.basic-addition-worksheets-page .library .image:hover .remove-button {
  display: inline-block;
}
.basic-addition-worksheets-page .library .image img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: auto;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.basic-addition-worksheets-page .library .remove-button {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  color: #c33f22;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.basic-addition-worksheets-page .library .remove-button:focus {
  outline: none;
  border: none;
}
.basic-addition-worksheets-page .dropzone {
  min-height: 0;
  border: 2px dashed #00c6bf;
  border-radius: 8px;
  background: #e4f8f7;
  padding: 20px 20px;
}
.basic-addition-worksheets-page .dropzone.dz-drag-hover {
  opacity: 0.7;
}
.basic-addition-worksheets-page .dropzone .dz-message {
  font-size: 1.6rem;
}
.basic-addition-worksheets-page .setting {
  padding: 32px 0;
  background-color: #f8f6f1;
  border-bottom: 1px solid #ddd;
}
@media print {
  .basic-addition-worksheets-page .setting {
    display: none;
  }
}
.basic-addition-worksheets-page .setting dl dt {
  clear: both;
  float: left;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 5px;
  width: 140px;
  font-size: 1.6rem;
}
.basic-addition-worksheets-page .setting dl dd {
  float: left;
  margin-bottom: 16px;
  width: calc(100% - 140px);
}
.basic-addition-worksheets-page .setting dl .label {
  padding-right: 8px;
}
.basic-addition-worksheets-page .setting dl input {
  margin-right: 16px;
}
.basic-addition-worksheets-page .setting dl input[type=checkbox] {
  position: relative;
  top: 6px;
  width: 16px;
  height: 16px;
}
.basic-addition-worksheets-page .setting .actions {
  margin-top: 24px;
  text-align: center;
}
.basic-addition-worksheets-page .setting .note {
  margin-top: 5px;
}
.basic-addition-worksheets-page .problem-text {
  margin: 40px 0;
  font-size: 2.4rem;
}
.basic-addition-worksheets-page .problems.text-only {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  font-family: "Roboto", sans-serif;
  font-size: 3.6rem;
  letter-spacing: 0.05em;
  counter-reset: number;
  list-style: none;
}
.basic-addition-worksheets-page .problems.text-only li {
  width: 50%;
}
@media print {
  .basic-addition-worksheets-page .problems.text-only li {
    margin-bottom: 0;
  }
}
.basic-addition-worksheets-page .problems.text-only li::before {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  min-width: 2em;
  font-size: 2rem;
  counter-increment: number;
  content: "(" counter(number) ")";
}
.basic-addition-worksheets-page .problems.text-only .sign {
  display: inline-block;
  margin: 0 16px;
}
.basic-addition-worksheets-page .problems.text-only .number {
  display: inline-block;
  min-width: 2em;
  font-size: 3rem;
  text-align: left;
}
@media print {
  .basic-addition-worksheets-page .problems.text-only .number {
    margin-bottom: 0;
  }
}
.basic-addition-worksheets-page .problems.text-only .number.left {
  text-align: right;
}
.basic-addition-worksheets-page .problems.text-only .answer {
  display: inline-block;
  position: relative;
  top: 0;
  width: 60px;
}
.basic-addition-worksheets-page .problems.text-only .answer.correct::after {
  position: absolute;
  width: 40px;
  height: 40px;
}
.basic-addition-worksheets-page .problems.text-only .frame {
  position: static;
  width: 60px;
  height: 60px;
  padding: 0;
  font-size: 3rem;
  text-align: center;
  border: 1px solid #666;
  border-radius: 10px;
  -webkit-transform: none;
          transform: none;
}
@media print {
  .basic-addition-worksheets-page .problems.text-only .frame {
    display: none;
  }
}
.basic-addition-worksheets-page .problems li {
  margin-bottom: 20px;
  page-break-inside: avoid;
}
.basic-addition-worksheets-page .problems .cols {
  display: -webkit-box;
  display: flex;
  margin: 0 -20px;
}
.basic-addition-worksheets-page .problems .col {
  margin: 0 20px;
  width: 30%;
}
.basic-addition-worksheets-page .problems .col:first-of-type {
  padding: 20px;
  background-color: #ebf9ff;
  border-radius: 10px;
}
.basic-addition-worksheets-page .problems .col:nth-of-type(2) {
  padding: 20px;
  background-color: #ebf9ff;
  border-radius: 10px;
}
.basic-addition-worksheets-page .problems .col:nth-of-type(2) {
  padding: 20px;
  background-color: #ebf9ff;
  border-radius: 10px;
}
.basic-addition-worksheets-page .problems .col:nth-of-type(3) {
  position: relative;
}
.basic-addition-worksheets-page .problems .answer {
  position: relative;
  width: 120px;
}
.basic-addition-worksheets-page .problems .answer.correct::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 80px;
  height: 80px;
  border: 5px solid #ff0000;
  border-radius: 200px;
  content: "";
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.basic-addition-worksheets-page .problems .frame {
  position: absolute;
  left: 0;
  top: 50%;
  width: 120px;
  height: 120px;
  font-size: 5rem;
  text-align: center;
  border: 1px solid #666;
  border-radius: 10px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.basic-addition-worksheets-page .problems .number {
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
}
.basic-addition-worksheets-page .problems .icons {
  font-size: 0;
  text-align: left;
}
.basic-addition-worksheets-page .problems .icons .group {
  display: inline-block;
  vertical-align: top;
  margin: 0 0 10px 0;
  padding: 5px;
  width: 100%;
  font-size: 0;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.basic-addition-worksheets-page .problems .icons .group .icon {
  width: 10%;
  margin-bottom: 0;
  padding-top: 15%;
}
.basic-addition-worksheets-page .problems .icon {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin-bottom: 5px;
  padding: 30% 5px 0 5px;
  width: 20%;
}
.basic-addition-worksheets-page .problems .icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: auto;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}